import { generateAsyncAction } from 'lib/action-generators';
import {
  CREATE_BRAND_EDUCATION,
  DELETE_BRAND_EDUCATION,
  LOAD_BRAND_EDUCATION,
  SEARCH_BRAND_EDUCATIONS,
  EDIT_BRAND_EDUCATION,
  CREATE_BRAND_RESOURCE,
  DELETE_BRAND_RESOURCE,
  EDIT_BRAND_RESOURCE,
  COMPLETE_BRAND_EDUCATION,
  USER_BRAND_EDUCATION,
} from './action-types';
import graphql from 'lib/api/graphql';

import searchBrandEducation from '../graphql/queries/search-brand-education';
import loadBrandEducation from '../graphql/queries/load-brand-education';
import createBrandEducation from '../graphql/mutations/create-brand-education';
import deleteBrandEducation from '../graphql/mutations/delete-brand-education';
import editBrandEducation from '../graphql/mutations/edit-brand-education';

import createBrandResource from '../graphql/mutations/create-brand-resource';
import deleteBrandResource from '../graphql/mutations/delete-brand-resource';
import editBrandResource from '../graphql/mutations/edit-brand-resource';

import completeBrandEducation from '../graphql/mutations/complete-brand-education.graphql';
import userBrandEducation from '../graphql/queries/users-brand-education.graphql';

const BrandEducationActions = {
  searchBrandEducation: searchData =>
    generateAsyncAction(
      SEARCH_BRAND_EDUCATIONS,
      searchData,
      graphql(searchBrandEducation, searchData),
      'searchBrandEducation'
    ),

  createBrandEducation: (id, details) =>
    generateAsyncAction(
      CREATE_BRAND_EDUCATION,
      { id, ...details },
      graphql(createBrandEducation, { id, ...details }),
      'createBrandEducation'
    ),

  deleteBrandEducation: id =>
    generateAsyncAction(
      DELETE_BRAND_EDUCATION,
      { id },
      graphql(deleteBrandEducation, { id }),
      'deleteBrandEducation'
    ),

  editBrandEducation: (id, details) =>
    generateAsyncAction(
      EDIT_BRAND_EDUCATION,
      { id, ...details },
      graphql(editBrandEducation, { id, ...details }),
      'editBrandEducation'
    ),

  loadBrandEducation: id =>
    generateAsyncAction(
      LOAD_BRAND_EDUCATION,
      { id },
      graphql(loadBrandEducation, { id }),
      'loadBrandEducation'
    ),

  createBrandResource: (id, details) =>
    generateAsyncAction(
      CREATE_BRAND_RESOURCE,
      { id, ...details },
      graphql(createBrandResource, { id, ...details }),
      'createBrandResource'
    ),

  editBrandResource: (id, details) =>
    generateAsyncAction(
      EDIT_BRAND_RESOURCE,
      { id, ...details },
      graphql(editBrandResource, { id, ...details }),
      'editBrandResource'
    ),

  deleteBrandResource: id =>
    generateAsyncAction(
      DELETE_BRAND_RESOURCE,
      { id },
      graphql(deleteBrandResource, { id }),
      'deleteBrandResource'
    ),

  completeBrandEducation: details =>
    generateAsyncAction(
      COMPLETE_BRAND_EDUCATION,
      { ...details },
      graphql(completeBrandEducation, { ...details }),
      'completeBrandEducation'
    ),

  userBrandEducation: () =>
    generateAsyncAction(
      USER_BRAND_EDUCATION,
      {},
      graphql(userBrandEducation),
      'userBrandEducation'
    ),
};

export default BrandEducationActions;
