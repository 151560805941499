import * as actionTypes from 'constants/actionTypes';
import createReducer from '../../../reducers/createReducer';
import Immutable from 'immutable';
import initialState from './initialState';
import { ROLES } from '@pomp-libs/core';

/**
 *
 * @param authUser
 * @param type
 */
function saveToLocalStorage(authUser, type) {
  if (type === 'auth') {
    window.localStorage.removeItem('pomp-authUser');
    window.localStorage.setItem('pomp-authUser', JSON.stringify(authUser));
  } else if (type === 'capture') {
    window.localStorage.removeItem('capturedUser');
    window.localStorage.setItem('capturedUser', JSON.stringify(authUser));
  } else {
    window.localStorage.removeItem('pomp-authUser');
    window.localStorage.removeItem('capturedUser');
    window.localStorage.setItem('pomp-authUser', JSON.stringify(authUser));
  }
}

let authUserFromLocalStorage = window.localStorage.getItem('pomp-authUser');
if (authUserFromLocalStorage)
  authUserFromLocalStorage = JSON.parse(authUserFromLocalStorage);
if (!authUserFromLocalStorage) authUserFromLocalStorage = {};

export default createReducer(
  initialState.merge({ ...authUserFromLocalStorage, loadedFromServer: false }),
  {
    [actionTypes.APP__START_FULFILLED]: (state, { payload }) => {
      if (!payload?.data) return state;

      const { data } = payload;

      return state.merge(
        Immutable.fromJS({
          loadedFromServer: true,
          addresses: data.addresses.filter(address => !!address.active),
          paymentMethods: data.paymentMethods.filter(paymentMethod =>
            paymentMethod ? !!paymentMethod.active : paymentMethod
          ),
          surveyAnswers: data.surveyAnswers,
          featureFlags: data?.featureFlags,
          subscription: data?.activeSubscription,
          previousSubscriptions: data?.subscriptions,
          estheticianPromoCode: data?.estheticianPromoCode?.code,
          license: data?.license,
          business: data?.userBusiness,
        })
      );
    },

    [actionTypes.ADDRESS__CREATE_FULFILLED]: (
      state,
      {
        payload: {
          data: { address },
        },
      }
    ) => {
      if (!address) return state;
      const index = state
        .get('addresses')
        .findIndex(a => a.get('id') === address.id);

      return state.updateIn(['addresses'], list =>
        index < 0
          ? list.push(Immutable.fromJS(address))
          : list.update(index, () => Immutable.fromJS(address))
      );
    },

    [actionTypes.ADDRESS__DELETE_FULFILLED]: (
      state,
      {
        payload: {
          data: { deleteAddress: addressId },
        },
      }
    ) => {
      if (!addressId) {
        return state;
      }
      return state.updateIn(['addresses'], list =>
        list.filter(address => address.get('id') !== addressId)
      );
    },

    [actionTypes.PAYMENT_METHOD__CREATE_FULFILLED]: (
      state,
      { payload: { data } }
    ) => {
      if (!data || !data.paymentMethod) {
        return state;
      }

      const index = state
        .get('paymentMethods')
        .findIndex(a => a.get('id') === data.paymentMethod.id);
      return state.updateIn(['paymentMethods'], list =>
        index < 0
          ? list.push(Immutable.fromJS(data.paymentMethod))
          : list.update(index, () => Immutable.fromJS(data.paymentMethod))
      );
    },

    [actionTypes.PAYMENT_METHOD__DELETE_FULFILLED]: (
      state,
      {
        payload: {
          data: { deletePaymentMethod: paymentMethodId },
        },
      }
    ) => {
      if (!paymentMethodId) {
        return state;
      }
      return state.updateIn(['paymentMethods'], list =>
        list.filter(
          paymentMethod => paymentMethod.get('id') !== paymentMethodId
        )
      );
    },

    [actionTypes.AUTH_USER__LOGIN_FULFILLED]: (
      state,
      {
        payload: {
          data: { login: loginRes, impersonate },
        },
      }
    ) => {
      const login = loginRes || impersonate;

      if (!login.status) return state;

      const authUser = {
        jwt: login.jwt,
        ...(login.impersonate && {
          impersonate: {
            id: login.user.id,
            email: login.user.email,
            role: login.user.role,
            tier: login.user.tier,
          },
        }),
        twilioAccessToken: login.twilioAccessToken,
        ...login.user,
      };

      saveToLocalStorage(authUser, 'auth');
      return state.merge(authUser);
    },

    [actionTypes.AUTH_USER__LOGOUT]: () => {
      saveToLocalStorage({});
      return initialState;
    },

    [actionTypes.AUTH_USER__REGISTER_FULFILLED]: (
      state,
      {
        payload: {
          data: { register = {} },
        },
      }
    ) => {
      if (!register?.status) return state;

      const authUser = {
        ...register.user,
        jwt: register.jwt,
        twilioAccessToken: register.twilioAccessToken,
      };

      saveToLocalStorage(authUser, 'auth');
      return state.merge(authUser);
    },

    [actionTypes.AUTH_USER__GET_REFERRALS_FULFILLED]: (state, { payload }) => {
      if (!payload?.data) return state;

      return state.merge(payload.data);
    },

    [actionTypes.AUTH_USER__REFRESH_TWILIO_TOKEN]: (
      state,
      { payload: { refreshTwilioToken } }
    ) => state.merge(refreshTwilioToken),

    [actionTypes.AUTH_USER__CAPTURE_FULLFILLED]: (
      state,
      {
        payload: {
          data: { capture },
        },
      }
    ) => {
      if (!capture.status) return state;

      const captureUser = {
        firstName: capture.user.firstName,
        lastName: capture.user.lastName,
        email: capture.user.email,
        captureId: capture.user.id,
      };
      saveToLocalStorage(captureUser, 'capture');
      return state.merge(captureUser);
    },

    [actionTypes.AUTH_USER__SET_SURVEY_COMPLETED]: state => {
      saveToLocalStorage({ ...state.toJS(), completedSurvey: true }, 'auth');
      return state.set('completedSurvey', true);
    },

    [actionTypes.AUTH_USER__DELETE_UNCAPTURED_USER]: (
      state,
      {
        payload: {
          data: { deleteUser },
        },
      }
    ) => {
      if (!deleteUser) return state;
      saveToLocalStorage({});
      return initialState;
    },

    [actionTypes.USERS__SUBMIT_LICENSE]: (state, { payload }) => {
      if (!payload?.data) return state;

      const { data } = payload;

      const authUserFromLocalStorage = JSON.parse(
        window.localStorage.getItem('pomp-authUser')
      );

      const isPendingEsthe =
        authUserFromLocalStorage.role === ROLES.ESTHETICIAN_PENDING;

      const role = { role: ROLES.ESTHETICIAN };

      if (isPendingEsthe) {
        saveToLocalStorage({ ...authUserFromLocalStorage, ...role }, 'auth');
      }

      return state.merge({
        license: data?.submitLicense.license,
        ...(data?.submitLicense.featureFlags && {
          featureFlags: data?.submitLicense.featureFlags,
        }),
        ...(isPendingEsthe && { ...role }),
      });
    },

    [actionTypes.USERS__LOAD_ONE]: (state, { payload, status }) => {
      if (status !== 'success') return state;

      const user = payload;
      // If User isn't found, don't save anything
      if (!user || user === 'Error: User not found' || user.id !== state.id)
        return state;

      // Add Users into list
      return {
        ...state,
        email: user?.email ?? state.email,
        role: user?.role ?? state.role,
        tier: user?.tier ?? state.tier,
      };
    },
  }
);
