import * as actionTypes from '../actions/action-types';
import createReducer from '../../../reducers/createReducer';
import Immutable from 'immutable';
import initialState from './initialState';

export default createReducer(initialState, {
  [actionTypes.NOTIFICATIONS__LOAD_ALL_PENDING]: state =>
    state.set('isLoading', true),

  [actionTypes.NOTIFICATIONS__LOAD_ALL_FULFILLED]: (
    state,
    { payload: { data } }
  ) =>
    state.merge({
      all: Immutable.fromJS(data.notifications),
      isLoading: false,
    }),

  [actionTypes.NOTIFICATION__SEARCH_FULFILLED]: (
    state,
    { payload: { data = {} } }
  ) =>
    state.merge({
      all: data?.notificationSearch,
      isLoading: false,
    }),
});
